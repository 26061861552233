import { CSSProperties, JSXElementConstructor, ReactElement, ReactNode, ReactPortal } from 'react';
import React from 'react'
/**
 * Interface für die MIcon-Komponentenprops.
 */
interface MIconProps {
    /**
     * Optionaler CSS-Klassename für das Icon.
     */
    className?: string;

    /**
     * Optionale Inline-Styles für das Icon.
     */
    style?: CSSProperties | undefined;

    /**
     * Optionale ID für das Icon-Element.
     */
    id?: string;

    /**
     * Flag, das angibt, ob die neuen Material Symbols-Icons verwendet werden sollen.
     */
    newMaterial?: boolean;

    /**
     * Optionale Variante des Icons (fill, outlined, rounded, sharp).
     */
    variant?: 'fill' | '-outlined' | '-rounded' | '-sharp' | undefined;

    /**
     * Der Inhalt des Icons, der gerendert werden soll. Kann eine Zeichenkette, Zahl, boolescher Wert, React-Element, iterable von React-Knoten, React-Portal oder null sein.
     */
    children:
        | string
        | number
        | boolean
        | ReactElement<any, string | JSXElementConstructor<any>>
        | Iterable<ReactNode>
        | ReactPortal
        | null
        | undefined;
}

/**
 * Rendert ein Material Design-Icon mit anpassbarer Stilisierung und Varianten.
 *
 * @param props Die Props für die Icon-Komponente.
 * @returns Das gerenderte Icon-Element.
 */
const MIcon: React.FC<MIconProps> = ({ className, style, id, newMaterial, variant, children }) => {
    const iconClassName = `material-${newMaterial ? 'symbols' : 'icons'}`;
    const baseVariant = variant !== undefined ? variant : '-outlined';
    const variantModifier = variant === 'fill' && newMaterial ? '-outlined' : '';
    const fullClassName = `${iconClassName} ${baseVariant}${variantModifier} ${className}`.trim();

    return (
        <span className={fullClassName} style={style} id={id}>
      {children}
    </span>
    );
};

export default MIcon;
